import { Radio } from 'antd';
import React from 'react';

import { useCustomer } from '../../hooks/use-customer';
import useCheckout from '../../hooks/useCheckout';
import { CustomerPaymentType, OrderDeliveryType } from '../../types/entities';
import Card, { CardBody, CardHeader } from '../ui/Card';

const DeliveryType = () => {
  const { customer } = useCustomer();
  const isCashOrder =
    customer?.metadata?.payment_type === CustomerPaymentType.CASH;

  return (
    <Card>
      <CardHeader>เลือกการจัดส่ง</CardHeader>
      <CardBody>
        <ul className="flex flex-col gap-y-3">
          <DeliveryTypeOption
            title="ส่งตามรอบ"
            subtitle="ส่งออเดอร์ล่วงหน้าต้นเดือน (บิลต้นเดือน)"
            deliveryType={OrderDeliveryType.SCHEDULE}
            isDisabled={isCashOrder}
          />
          <DeliveryTypeOption
            title="ส่งด่วน"
            subtitle="จัดส่งด่วนพิเศษ (บิลรายวัน)"
            deliveryType={OrderDeliveryType.EXPRESS}
          />
          <DeliveryTypeOption
            title="ส่งปกติ"
            subtitle="จัดส่งตามปกติ (บิลรายวัน)"
            deliveryType={OrderDeliveryType.REGULAR}
          />
        </ul>
      </CardBody>
    </Card>
  );
};

const DeliveryTypeOption = ({
  title,
  subtitle,
  deliveryType,
  isDisabled = false,
}: {
  title: string;
  subtitle: string;
  deliveryType: OrderDeliveryType;
  isDisabled?: boolean;
}) => {
  const { deliveryType: currentDeliveryType, setDeliveryType } = useCheckout();

  return (
    <li>
      <Radio
        checked={currentDeliveryType === deliveryType}
        onChange={() => setDeliveryType(deliveryType)}
        className="!m-0 !flex w-full !items-center rounded-md border border-light-border bg-neutral-1 !p-4 shadow-small"
        disabled={isDisabled}
      >
        <span className="ml-2 font-semibold enabled:text-light-title">
          {title}
        </span>
        <span> — {subtitle}</span>
      </Radio>
    </li>
  );
};

export default DeliveryType;
